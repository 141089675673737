/* Set the maximum width of the components based on screen size */
/* @media only screen and (min-width: 1400px) {
  .component {
    max-width: calc(100% / 2);
  }
} */

@media only screen and (min-width: 1000px)  {
  .component {
    max-width: calc(100% / 2.5);
  }
}

@media only screen and (max-width: 1000px) {
  .component {
    max-width: 80%;
  }
}

/* Allow components to float into position */
.component {
  float: left;
  width: 100%;
  padding-left: 130px

}

